import React from 'react';

function IconDefs() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
      <symbol id="arrow-up" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.2102 16H7.79021H7.79021C7.11177 16.001 6.49317 15.6119 6.20021 15H6.20021C5.85738 14.2742 5.95829 13.4164 6.46021 12.79L10.6702 7.68998V7.68998C11.3068 6.95544 12.4184 6.87605 13.1529 7.51267C13.2161 7.56748 13.2754 7.62673 13.3302 7.68998L17.5402 12.79C18.0421 13.4164 18.143 14.2742 17.8002 15V15C17.5072 15.6119 16.8886 16.001 16.2102 16L16.2102 16Z"
        />
      </symbol>

      <symbol id="arrow-down" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0002 17C11.4832 16.9974 10.9948 16.7624 10.6702 16.36L6.46021 11.26L6.46021 11.26C5.95829 10.6336 5.85738 9.77581 6.20021 9.05C6.47854 8.41856 7.1002 8.00803 7.79021 8H16.2102V8C16.9002 8.00803 17.5219 8.41856 17.8002 9.05V9.05C18.143 9.77581 18.0421 10.6336 17.5402 11.26L13.3302 16.36C13.0056 16.7624 12.5172 16.9974 12.0002 17Z"
        />
      </symbol>
      <symbol id="arrow-right" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM17 11.91V12C16.9997 12.134 16.9725 12.2667 16.92 12.39C16.8713 12.5092 16.7999 12.6178 16.71 12.71L13.71 15.71L13.7042 15.7158C13.3121 16.1047 12.6789 16.1021 12.29 15.71L12.2842 15.7042C11.8953 15.3121 11.8979 14.6789 12.29 14.29L13.59 13H8C7.44772 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H13.66L12.43 9.69C12.0489 9.28959 12.0646 8.65608 12.465 8.275C12.8654 7.89392 13.4989 7.90959 13.88 8.31L16.74 11.31C16.7734 11.3509 16.8035 11.3944 16.83 11.44C16.8762 11.4973 16.9133 11.5614 16.94 11.63C16.9752 11.7194 16.9954 11.814 17 11.91Z"
        />
      </symbol>

      <symbol id="arrow-left" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5229 6.47715 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47715 17.5229 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 16.4183 16.4183 20 12 20ZM10.34 11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H10.41L11.71 14.28L11.7158 14.2858C12.1047 14.6779 12.1021 15.3111 11.71 15.7L11.7042 15.7058C11.3121 16.0947 10.6789 16.0921 10.29 15.7L7.29 12.7C7.20005 12.6078 7.12874 12.4992 7.08 12.38C7.02876 12.2598 7.00158 12.1307 7 12L7.02 11.91C7.02458 11.814 7.04485 11.7194 7.08 11.63C7.10674 11.5614 7.14384 11.4973 7.19 11.44C7.21652 11.3944 7.24662 11.3509 7.28 11.31L10.14 8.31C10.5211 7.90959 11.1546 7.89392 11.555 8.275C11.9554 8.65608 11.9711 9.28959 11.59 9.69L10.34 11Z"
        />
      </symbol>

      <symbol id="calendar" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 4H17V3C17 2.44771 16.5523 2 16 2C15.4477 2 15 2.44771 15 3V4H9V3C9 2.44771 8.55229 2 8 2C7.44771 2 7 2.44771 7 3V4H6H6C4.34315 4 3 5.34315 3 7V19C3 20.6569 4.34315 22 6 22H18C19.6569 22 21 20.6569 21 19V7C21 5.34315 19.6569 4 18 4V4ZM8 17C7.44771 17 7 16.5523 7 16C7 15.4477 7.44771 15 8 15C8.55229 15 9 15.4477 9 16C9 16.5523 8.55229 17 8 17ZM16 17H12C11.4477 17 11 16.5523 11 16C11 15.4477 11.4477 15 12 15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17ZM19 11H5V7C5 6.44771 5.44772 6 6 6H7V7C7 7.55229 7.44772 8 8 8C8.55229 8 9 7.55229 9 7V6H15V7V7C15 7.55229 15.4477 8 16 8C16.5523 8 17 7.55229 17 7V6H18C18.5523 6 19 6.44772 19 7V11Z"
        />
      </symbol>

      <symbol id="checkbox-empty" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 21H6H6C4.34315 21 3 19.6569 3 18V6V6C3 4.34315 4.34315 3 6 3H18C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21ZM6 5C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V6C19 5.44771 18.5523 5 18 5L6 5Z"
        />
      </symbol>

      <symbol id="checkbox-filled" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 3H6H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H18C19.6569 21 21 19.6569 21 18V6C21 4.34315 19.6569 3 18 3V3ZM16.3 9.61L11.73 15.61C11.5412 15.8552 11.2495 15.9993 10.94 16C10.6322 16.0017 10.3408 15.8615 10.15 15.62L7.71 12.51C7.37035 12.0737 7.4487 11.4447 7.885 11.105C8.32131 10.7653 8.95034 10.8437 9.29 11.28L10.92 13.36L14.7 8.36C15.0369 7.91817 15.6682 7.83311 16.11 8.17C16.5518 8.50689 16.6369 9.13817 16.3 9.58V9.61Z"
        />
      </symbol>

      <symbol id="rounded-box" viewBox="0 0 24 24">
        <rect width="24" height="24" rx="4" stroke="none" />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="none" />
      </symbol>

      <symbol id="chevron-left" viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.813 22.6667C17.4501 22.6653 17.1034 22.516 16.853 22.2533L11.7063 16.92H11.7063C11.1981 16.4015 11.1981 15.5718 11.7063 15.0533L17.0397 9.72C17.5625 9.19717 18.4102 9.19717 18.933 9.72C19.4558 10.2428 19.4558 11.0905 18.933 11.6133L14.533 16L18.773 20.4C19.29 20.9201 19.29 21.7599 18.773 22.28C18.5181 22.5329 18.172 22.6723 17.813 22.6667Z"
        />
      </symbol>

      <symbol id="chevron-right" viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.0002 22.6667C13.6458 22.6687 13.3052 22.5296 13.0536 22.28C12.5307 21.7614 12.5273 20.9172 13.0458 20.3944C13.0484 20.3918 13.051 20.3892 13.0536 20.3867L17.4669 16L13.2269 11.5867V11.5867C12.7099 11.0666 12.7099 10.2267 13.2269 9.70665L13.2269 9.70665C13.7455 9.18382 14.5897 9.18036 15.1125 9.69892C15.1151 9.70149 15.1177 9.70407 15.1202 9.70665L20.2669 15.04H20.2669C20.7751 15.5584 20.7751 16.3882 20.2669 16.9067L14.9336 22.24C14.6918 22.501 14.3558 22.6546 14.0002 22.6667L14.0002 22.6667Z"
        />
      </symbol>

      <symbol id="close" viewBox="0 0 48 48">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.8199 24.0002L35.4199 15.4202C36.2041 14.6359 36.2041 13.3644 35.4199 12.5802C34.6356 11.7959 33.3641 11.7959 32.5799 12.5802L23.9999 21.1802L15.4199 12.5802L15.4199 12.5802C14.6356 11.7959 13.3641 11.7959 12.5799 12.5802C11.7956 13.3644 11.7956 14.6359 12.5799 15.4202L21.1799 24.0002L12.5799 32.5802C11.7956 33.358 11.7904 34.6243 12.5683 35.4086C12.5721 35.4125 12.576 35.4163 12.5799 35.4202H12.5799C13.3577 36.2044 14.624 36.2096 15.4083 35.4318C15.4122 35.4279 15.416 35.4241 15.4199 35.4202L23.9999 26.8202L32.5799 35.4202V35.4202C33.3577 36.2044 34.624 36.2096 35.4083 35.4318C35.4122 35.4279 35.416 35.4241 35.4199 35.4202C36.2041 34.6423 36.2093 33.376 35.4315 32.5918C35.4276 32.5879 35.4238 32.584 35.4199 32.5802L26.8199 24.0002Z"
        />
      </symbol>

      <symbol id="download" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0019 12.0625C11.0006 12.0418 11 12.021 11 12V4C11 3.44771 11.4477 3 12 3C12.5523 3 13 3.44771 13 4V12C13 12.0024 13 12.0047 13 12.0071L15.4 10.2C15.8418 9.86863 16.4686 9.95817 16.8 10.4C17.1314 10.8418 17.0418 11.4686 16.6 11.8L12.6 14.8C12.4269 14.9298 12.2164 15 12 15C11.7927 15.0016 11.59 14.9387 11.42 14.82L7.42 12C6.97119 11.6816 6.86393 11.0604 7.18 10.61C7.49694 10.1577 8.12052 10.048 8.57282 10.3649L8.58 10.37L11.0019 12.0625ZM4 17V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V17C20 16.4477 19.5523 16 19 16C18.4477 16 18 16.4477 18 17V18H6V17C6 16.4477 5.55228 16 5 16C4.44772 16 4 16.4477 4 17Z"
        />
      </symbol>

      <symbol id="external-link" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 12C19 11.4477 19.4477 11 20 11C20.5523 11 21 11.4477 21 12V18C21 19.6568 19.6569 21 18 21H6C4.34315 21 3 19.6568 3 18V6C3 4.34315 4.34315 3 6 3H12C12.5523 3 13 3.44771 13 4C13 4.55228 12.5523 5 12 5H6C5.44772 5 5 5.44771 5 6V18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V12ZM17.58 5H16C15.4477 5 15 4.55228 15 4C15 3.44771 15.4477 3 16 3H20C20.5523 3 21 3.44771 21 4V8C21 8.55228 20.5523 9 20 9C19.4477 9 19 8.55228 19 8V6.42L12.71 12.7L12.7042 12.7058C12.3121 13.0947 11.6789 13.0921 11.29 12.7L11.2842 12.6942C10.8953 12.3021 10.8979 11.6689 11.29 11.28L17.58 5Z"
        />
      </symbol>

      <symbol id="info" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.47715 6.47715 2 12 2C17.5229 2 22 6.47715 22 12C22 17.5229 17.5229 22 12 22C6.47715 22 2 17.5229 2 12ZM4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12ZM12.7071 7.29289C13.0976 7.68341 13.0976 8.31658 12.7071 8.7071C12.3166 9.09762 11.6834 9.09762 11.2929 8.7071C10.9024 8.31658 10.9024 7.68341 11.2929 7.29289C11.6834 6.90237 12.3166 6.90237 12.7071 7.29289ZM11 11C11 10.4477 11.4477 10 12 10C12.5523 10 13 10.4477 13 11V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V11Z"
        />
      </symbol>

      <symbol id="search" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.71 19.29L17.31 15.9C18.407 14.5024 19.0022 12.7767 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C12.7767 19.0022 14.5025 18.407 15.9 17.31L19.29 20.71C19.6789 21.1021 20.3121 21.1047 20.7042 20.7158C20.7061 20.7139 20.7081 20.7119 20.71 20.71V20.71C21.1021 20.3211 21.1047 19.6879 20.7158 19.2958C20.7139 19.2939 20.7119 19.2919 20.71 19.29L20.71 19.29ZM5 11V11C5 7.68629 7.68629 5 11 5C14.3137 5 17 7.68629 17 11C17 14.3137 14.3137 17 11 17C7.68629 17 5 14.3137 5 11L5 11Z"
        />
      </symbol>
      <symbol id="share" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18 15C17.2144 15.0006 16.4604 15.3094 15.9 15.86L8.00003 12.34V12V11.67L15.9 8.13999C17.0833 9.29979 18.9827 9.28079 20.1425 8.09756C21.3023 6.91433 21.2833 5.01493 20.1 3.85513C18.9168 2.69533 17.0174 2.71433 15.8576 3.89756C15.3073 4.45895 14.9994 5.21389 15 5.99999V6.33999L7.10003 9.85999C5.91814 8.69883 4.01872 8.71563 2.85756 9.89752C1.6964 11.0794 1.7132 12.9788 2.89508 14.14C4.06221 15.2867 5.9329 15.2867 7.10003 14.14L15 17.67V18V18C15 19.6568 16.3432 21 18 21C19.6569 21 21 19.6568 21 18C21 16.3431 19.6569 15 18 15L18 15Z"
        />
      </symbol>
      <symbol id="view" viewBox="0 0 24 24">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.8701 11.5C21.2301 10.39 17.7101 4.81999 11.7301 4.99999C6.20007 5.13999 3.00007 9.99999 2.13007 11.5C1.95144 11.8094 1.95144 12.1906 2.13007 12.5C2.76007 13.59 6.13007 19 12.0201 19H12.2701C17.8001 18.86 21.0101 14 21.8701 12.5C22.0487 12.1906 22.0487 11.8094 21.8701 11.5ZM12.0001 15.5C10.0671 15.5 8.50007 13.933 8.50007 12C8.50007 10.067 10.0671 8.49999 12.0001 8.49999C13.9331 8.49999 15.5001 10.067 15.5001 12C15.5001 13.933 13.9331 15.5 12.0001 15.5ZM13.0608 13.0606C13.6466 12.4748 13.6466 11.5251 13.0608 10.9393C12.475 10.3535 11.5252 10.3535 10.9394 10.9393C10.3537 11.5251 10.3537 12.4748 10.9394 13.0606C11.5252 13.6464 12.475 13.6464 13.0608 13.0606Z"
        />
      </symbol>
    </svg>
  );
}

export default IconDefs;
